.sprite_svg {
  display: none;
  width: 0;
  height: 0;
}
.icon_svg {
  position: relative;
  display: inline-block;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  transition: color 0.4s ease-in-out;
  stroke-width: 0;
  fill: currentColor;
}
/*.icon-small {
  width: 0.75em;
  height: 0.75em;
}

.icon-big {
  width: 1.5em;
  height: 1.5em;
}

.icon-large {
  width: 2em;
  height: 2em;
}*/
.medium {
  font-family: ubuntumedium, arial, Helvetica, sans-serif;
}
strong,
.bold {
  font-weight: bold;
  font-family: ubuntubold, arial, Helvetica, sans-serif;
}
.bigger {
  font-size: 22px;
  line-height: 1.2;
}
.big {
  font-size: 26px;
  line-height: 1.2;
}
.uppercase {
  text-transform: uppercase;
}
.nodecoration {
  text-decoration: none;
}
.underline {
  border-bottom: 1px solid #000000;
}
.nowrap {
  white-space: nowrap;
}
.red {
  color: #eb1c24;
}
.bluedark {
  color: #3f59a2;
}
.bluelight {
  color: #1a81e0;
}
.orange {
  color: #e36c1b;
}
.grey-1 {
  color: #444;
}
.grey-2 {
  color: #555;
}
.grey-3 {
  color: #666;
}
.grey-4 {
  color: #888;
}
.grey-5 {
  color: #bebebe;
}
.grey-6 {
  color: #ddd;
}
.grey-7 {
  color: #eee;
}
.grey-8 {
  color: #f5f5f5;
}
.black {
  color: #000000;
}
.white {
  color: #ffffff;
}
.green {
  color: #229660;
}
/*a.black {
  color: @black;

  &:hover,
  &:focus,
  &:active {
    color: @red;
  }
}*/
.link-arrow {
  display: flex;
  align-items: center;
  color: #000000;
}
.link-arrow .icon_svg {
  width: 8px;
  height: 12px;
  margin-right: 10px;
  color: #e36c1b;
}
.link-arrow:hover {
  color: #229660;
}
.link-arrow:hover .icon_svg {
  color: #229660;
}
.button {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none !important;
  font-family: ubuntumedium, arial, Helvetica, sans-serif;
  cursor: pointer;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
}
.button_orange {
  padding: 10px 48px;
  color: #ffffff;
  background-color: #e36c1b;
}
.button_orange:hover {
  color: #ffffff;
  background-color: #229660;
}
.button_blue {
  padding: 10px 48px;
  color: #ffffff;
  background-color: #1a81e0;
}
.button_blue:hover {
  color: #ffffff;
  background-color: #229660;
}
.button_green {
  color: #ffffff;
  background-color: #229660;
}
.button_green:hover {
  color: #ffffff;
  background-color: #1a81e0;
}
.button_red {
  color: #ffffff;
  background-color: #eb1c24;
}
.button_red:hover {
  color: #ffffff;
  background-color: #1a81e0;
}
.button_grey {
  color: #ffffff;
  background-color: #888;
}
.button_grey:hover {
  color: #ffffff;
  background-color: #229660;
}
.button_white {
  color: #000000;
  background-color: #ffffff;
}
.button_white:hover {
  color: #ffffff;
  background-color: #229660;
}
.button_facebook {
  color: #ffffff;
  background-color: #3f59a2;
}
.button_facebook svg {
  width: 20px;
  height: 20px;
}
.button_facebook:hover {
  color: #ffffff;
  background-color: #1a81e0;
}
.floatleft {
  float: left;
}
.floatright {
  float: right;
}
.floatend {
  clear: both;
}
.clearfix:after,
.clearfix:before {
  display: table;
  clear: both;
  content: " ";
}
.aligncenter {
  text-align: center;
}
.alignleft {
  text-align: left;
}
.alignright {
  text-align: right;
}
.uppercase {
  text-transform: uppercase;
}
/*.hidden {
  opacity: 0;
}

.visible,
.show {
  opacity: 1;
}

.notshow {
  display: none;
}*/
.notdisplay {
  display: none;
}
.overhidden {
  overflow: hidden;
}
.line {
  border-top: 1px solid #e2e3e5;
}
.shadow-right {
  background: url("/images/shadow-right.webp") no-repeat top right;
}
.shadow-left {
  background: url("/images/shadow-left.webp") no-repeat top left;
}
.no-webp .shadow-right {
  background: url("/css/images/shadow-right.jpg") no-repeat top right;
}
.no-webp .shadow-left {
  background: url("/css/images/shadow-left.jpg") no-repeat top left;
}
.color-line {
  width: 100%;
  height: 2px;
  background: #f0476f;
  background: linear-gradient(90deg, #f0476f 0%, #f0476f 20%, #ffd066 20%, #ffd066 40%, #05d6a1 40%, #05d6a1 60%, #118ab2 60%, #118ab2 80%, #043b4c 80%, #043b4c 100%);
}
@media only screen and (min-width: 1200px) {
  .color-line {
    background: linear-gradient(90deg, #f0476f 0%, #f0476f 10%, #ffd066 10%, #ffd066 20%, #05d6a1 20%, #05d6a1 30%, #118ab2 30%, #118ab2 40%, #043b4c 40%, #043b4c 50%, #f0476f 50%, #f0476f 60%, #ffd066 60%, #ffd066 70%, #05d6a1 70%, #05d6a1 80%, #118ab2 80%, #118ab2 90%, #043b4c 90%, #043b4c 100%);
  }
}
.overhiddenx {
  overflow-x: hidden;
}
.content_target {
  height: auto;
  max-height: 0;
  opacity: 0;
  transition: opacity 0.4s ease-in-out, max-height 0.4s ease-in-out;
}
.content_target .content_target--overflow {
  max-height: 0;
  overflow: hidden;
}
@media only screen and (max-width: 699px) {
  .content_target .content_target--overflowres {
    max-height: 0;
    overflow: hidden;
  }
}
.content_target.show {
  max-height: 2000px;
  opacity: 1;
}
.content_target.show .content_target--overflow {
  max-height: 2000px;
  overflow: unset;
}
@media only screen and (max-width: 699px) {
  .content_target.show .content_target--overflowres {
    max-height: 2000px;
    overflow: unset;
  }
}
@media only screen and (min-width: 1200px) {
  .content_target--showfrom.hide {
    max-height: 2000px;
    opacity: 1;
  }
  .content_target--showfrom.hide .content_target--overflow {
    max-height: 2000px;
    overflow: unset;
  }
}
.selectstyle__input {
  display: inline-block;
  margin-right: 20px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  background: url("/images/svg/arrow-down.svg") no-repeat right 15px center #f8f6f5;
  background-size: 12px;
  border: 1px solid #d2cbc6;
}
.selectstyle__input select {
  width: 100%;
  display: inline-block;
  padding: 15px 35px 15px 15px;
  color: #000000;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.selectstyle__input select:hover {
  cursor: pointer;
}
.selectstyle__input select option {
  display: block;
  color: #000000;
  font-size: 16px;
  background-color: #fff;
}
.selectstyle__input.noradius select {
  border-radius: 0;
}
.selectstyle__input--short {
  width: auto;
}
.selectstyle__input--short select {
  width: auto;
}
.block {
  display: block;
}
.blockin {
  display: inline-block;
}
.imgnormalize-wrapper {
  font-size: 0;
}
.imgnormalize {
  line-height: 1;
  vertical-align: middle;
}
.imgresponzive {
  max-width: 100%;
  height: auto;
}
@media only screen and (max-width: 499px) {
  .tablebreak500 td {
    display: block;
  }
}
.mb0 {
  margin-bottom: 0;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb25 {
  margin-bottom: 25px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb35 {
  margin-bottom: 35px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb45 {
  margin-bottom: 45px;
}
.mt0 {
  margin-top: 0;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt25 {
  margin-top: 25px;
}
.mt30 {
  margin-top: 30px;
}
.mt35 {
  margin-top: 35px;
}
.mt40 {
  margin-top: 40px;
}
.mt45 {
  margin-top: 45px;
}
.mt100 {
  margin-top: 100px;
}
.ml0 {
  margin-left: 0;
}
.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.ml25 {
  margin-left: 25px;
}
.ml30 {
  margin-left: 30px;
}
.ml35 {
  margin-left: 35px;
}
.ml40 {
  margin-left: 40px;
}
.ml45 {
  margin-left: 45px;
}
.mr0 {
  margin-right: 0;
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mr15 {
  margin-right: 15px;
}
.mr20 {
  margin-right: 20px;
}
.mr25 {
  margin-right: 25px;
}
.mr30 {
  margin-right: 30px;
}
.mr35 {
  margin-right: 35px;
}
.mr40 {
  margin-right: 40px;
}
.mr45 {
  margin-right: 45px;
}
.pt0 {
  padding-top: 0;
}
.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt15 {
  padding-top: 15px;
}
.pt20 {
  padding-top: 20px;
}
.pt30 {
  padding-top: 30px;
}
.pt35 {
  padding-top: 35px;
}
.pt40 {
  padding-top: 40px;
}
.pt45 {
  padding-top: 45px;
}
.pt50 {
  padding-top: 50px;
}
.pt60 {
  padding-top: 60px;
}
.pb0 {
  padding-bottom: 0;
}
.pb5 {
  padding-bottom: 5px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb15 {
  padding-bottom: 15px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb35 {
  padding-bottom: 35px;
}
.pb40 {
  padding-bottom: 40px;
}
.pb45 {
  padding-bottom: 45px;
}
.pb50 {
  padding-bottom: 50px;
}
/*# sourceMappingURL=css/helpers.css.map */